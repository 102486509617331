import { Authorization, NavigationBar, Error } from 'common';
import { ThemeProvider } from '@mui/material';
import { theme } from 'theme';
import {
  FriendsPage,
  InfoPage,
  LaunchPage,
  StoryPage,
  TravelPage,
} from 'pages';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { useState } from 'react';

const App = () => {
  const [authorized, setAuthorized] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      {!authorized ? (
        <Authorization setAuthorized={setAuthorized} />
      ) : (
        <>
          <NavigationBar />
          <Routes>
            <Route path="/" element={<LaunchPage />} />
            <Route path="/story" element={<StoryPage />} />
            <Route path="/info" element={<InfoPage />} />
            <Route path="/travel" element={<TravelPage />} />
            <Route path="/friends" element={<FriendsPage />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </>
      )}
    </ThemeProvider>
  );
};

export default App;
