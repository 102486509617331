import { Box, Button, TextField, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';

const { REACT_APP_PASSWORD } = process.env;

type AuthorizationProps = {
  setAuthorized: Dispatch<SetStateAction<boolean>>;
};

export const Authorization = ({ setAuthorized }: AuthorizationProps) => {
  const [password, setPassword] = useState('');
  const [incorrect, setIncorrect] = useState(false);

  const handleSubmit = () => {
    if (password === REACT_APP_PASSWORD) {
      setAuthorized(true);
    } else {
      setIncorrect(true);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="90vh"
    >
      <Typography variant="h4" mb="1rem">
        Welcome!
      </Typography>
      <Typography mb="2rem">Please enter the password to continue.</Typography>
      <TextField
        label="Password"
        type="password"
        error={incorrect}
        helperText={incorrect ? 'Sorry, wrong password.' : ''}
        value={password}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit();
          }
        }}
        onChange={(e) => {
          setIncorrect(false);
          setPassword(e.target.value);
        }}
        sx={{ mb: '2rem', height: '60px', width: '250px' }}
      />
      <Button disabled={!password} variant="contained" onClick={handleSubmit}>
        Submit
      </Button>
    </Box>
  );
};
