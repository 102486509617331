import { Box, useMediaQuery, useTheme } from '@mui/material';
import Launch1 from 'assets/launch/launch1.jpg';
import Launch2 from 'assets/launch/launch2.jpg';
import Launch3 from 'assets/launch/launch3.jpg';
import Launch4 from 'assets/launch/launch4.jpg';
import Launch5 from 'assets/launch/launch5.jpg';
import { PageContainer } from 'common';

export const LaunchPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PageContainer>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box justifyContent="center" display="flex" mb="3rem">
          <img
            src={Launch4}
            style={{ maxWidth: `${isSmallScreen ? '90vw' : '40vw'}` }}
            alt="Launch 4"
          />
        </Box>
        <Box justifyContent="center" display="flex" mb="3rem">
          <img
            src={Launch1}
            style={{ maxWidth: `${isSmallScreen ? '90vw' : '40vw'}` }}
            alt="Launch 1"
          />
        </Box>
        <Box justifyContent="center" display="flex" mb="3rem">
          <img
            src={Launch3}
            style={{ maxWidth: `${isSmallScreen ? '45vw' : '20vw'}` }}
            alt="Launch 3"
          />
        </Box>
        <Box justifyContent="center" display="flex" mb="3rem">
          <img
            src={Launch2}
            style={{ maxWidth: `${isSmallScreen ? '90vw' : '40vw'}` }}
            alt="Launch 2"
          />
        </Box>
        <Box justifyContent="center" display="flex" mb="5rem">
          <img
            src={Launch5}
            style={{ maxWidth: `${isSmallScreen ? '90vw' : '40vw'}` }}
            alt="Launch 5"
          />
        </Box>
      </Box>
    </PageContainer>
  );
};
